import * as React from "react";
import Layout from "../components/layout";
import Link from "../components/link";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

// markup
const NotFoundPage = () => {
    return (
        <Layout pageTitle="contact">
            <div className="my-4 max-w-5xl mx-2 md:mx-auto md:mt-6 xl:mx-auto md:px-4">
                <div className="bg-white shadow-md p-5 md:p-12 border">
                    <p className="text-center mb-10"><QuestionMarkCircleIcon className="h-14 w-14 inline-block text-gray-500" /></p>
                    <h2 className="font-bold text-5xl mb-10 text-main-dark text-center">Error 404</h2>
                    <p className="text-center">Sorry, can't find the page you're looking for...</p>
                    <p className="text-center mt-2"><Link to="/" className="underline hover:font-bold">Back to first page?</Link></p>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
